/* src/CircularProgressBar.css */
.circular-progress {
    width: 100px;
    height: 100px;
    position: relative;
  }

  .circular-progress__svg {
    width: 100%;
    height: 100%;
  }

  .circular-progress__background {
    fill: none;
    stroke: #ddd;
    stroke-width: 4;
  }

  .circular-progress__circle {
    fill: none;
    stroke: #007bff; /* Color de la barra de progreso */
    stroke-width: 4;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s;
  }

  .circular-progress__percentage {
    text-anchor: middle;
    dominant-baseline: middle;
    font-size: 20px;
    fill: #333;
  }
